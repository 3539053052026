@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

ul {
  list-style: none;
}

li {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: aliceblue;
}

.flex {
  display: flex;
  align-items: center;
}

.main-nav {
  background-color: #0e1630;
  justify-content: space-between;
  height: 90px;
  border: 1px solid white;
}

.title {
  color: aliceblue;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 35px;
}

nav {
  position: fixed;
  width: 100%;
  z-index: 10;
}

.nav-list {
  gap: 70px;
  margin-right: 20px;
}

li:hover {
  color: yellow;
  cursor: pointer;
}

.nav-item {
  color: aliceblue;
  text-decoration: none;
}

.nav-item:hover {
  color: yellow;
  text-decoration: none;
}

.nav-toggle {
  display: none;
}

.title-first-letter {
  color: yellow;
}

.media-links {
  gap: 15px;
}

.media-links a {
  color: aliceblue;
}

.media-links p {
  color: aliceblue;
  font-size: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.container {
  /* max-width: 1180px; */
  margin-inline: auto;
  padding-inline: 150px;
  overflow: hidden;
}

.introduction {
  margin-top: 150px;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: aliceblue;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 50%;
  }
}

@keyframes typing-reverse {
  from {
    width: 50%;
  }
  to {
    width: 0;
  }
}

@keyframes cursor {
  50% {
    border-color: transparent;
  }
}

h1 {
  color: yellow;
  font-size: 50px;
}

h3,
h4 {
  color: yellow;
}

.introduction h2 {
  color: aliceblue;
  font-size: 40px;
}

.introduction h4 {
  color: #808dad;
  font-size: 25px;
}

.writing {
  display: inline-block;
  border-right: 5px solid #808dad;
  overflow: hidden;
  animation: typing 4s steps(33), cursor 0.5s step-end infinite;
}

.introduction p {
  color: #808dad;
  font-size: 25px;
}

.introduction button {
  width: 250px;
  height: 70px;
  background-color: black;
  color: aliceblue;
  font-size: 25px;
  border-radius: 10px;
  margin-top: 25px;
  margin-left: 10px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.profile-container {
  margin-top: 50px;
  margin-right: 150px;
}

.profile {
  width: 600px;
  height: 550px;
  border-radius: 300px;
}

.card {
  width: auto;
  height: auto;
  border: 1px solid white;
  margin-right: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #171f38;
  transition: 0.3s;
}

.card:hover {
  transform: scale(1.02);
  filter: blur(5px);
}

footer {
  justify-content: center;
  width: 100%;
  height: 75px;
  border: 1px solid white;
  color: aliceblue;
  margin-top: 20px;
}

.details-section {
  justify-content: space-between;
}

.connect-me {
  color: white;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  margin-bottom: 175px;
  h2 {
    color: yellow;
  }
  .location {
    font-size: 15px;
    margin-top: 30px;
    margin-bottom: 20px;
    span {
      margin-left: 10px;
    }
  }

  .mail {
    font-size: 15px;
    margin-bottom: 20px;
    span {
      margin-left: 10px;
    }
  }
}

.contact-me {
  color: white;
  padding-top: 20px;
  margin-right: 25px;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  h2 {
    color: yellow;
  }
  input {
    width: 400px;
    height: 45px;
    padding-left: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    color: white;
    background-color: #323847;
  }
  button {
    width: 407px;
    height: 40px;
    color: white;
    font-size: 15px;
    border-radius: 10px;
    background-color: rgb(82, 161, 201);
  }
  #message {
    height: 130px;
  }
}

.card-section {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  img {
    max-width: 385px;
    height: 250px;
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
  }
  h2 {
    color: aliceblue;
    margin-left: 10px;
  }
  button {
    min-width: 80px;
    width: auto;
    height: 40px;
    color: white;
    background-color: #8e99b5;
    border-radius: 10px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
  button:hover {
    color: #8e99b5;
    background-color: white;
  }
}

.achieve-tile {
  margin-top: 40px;
  border: 1px solid white;
  border-radius: 10px;
  height: 150px;
  justify-content: space-between;
  color: white;
  font-size: 30px;
  padding-left: 50px;
  padding-right: 50px;
}

.tile-number {
  align-items: center;
  font-size: 50px;
}

.projects {
  margin-top: 100px;
}

.review-links {
  color: white;
  font-size: 32px;
  a {
    color: yellow;
    text-decoration: none;
  }
}

.skill-list {
  color: white;
  font-size: 32px;
  ul {
    li {
      color: yellow;
      margin-bottom: 10px;
      i {
        margin-right: 20px;
      }
    }
  }
}

.rating {
  margin-left: 30px;
}

/* shimmer css */

.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  border: 1px solid white;
  border-radius: 10px;
  padding-top: 60px;
}

.shimmer-card {
  width: 250px;
  height: 275px;
  border: 1px solid white;
  border-radius: 10px;
  margin: 10px;
  .image-box {
    width: 185px;
    height: 175px;
    border: 1px solid white;
    border-radius: 10px;
    margin-left: 30px;
    margin-bottom: 15px;
  }
  .title {
    width: 150px;
    height: 20px;
    border: 1px solid white;
    border-radius: 10px;
    margin-left: 30px;
    margin-bottom: 20px;
  }
  .tag {
    width: 70px;
    height: 25px;
    border: 1px solid white;
    border-radius: 10px;
    margin-left: 30px;
    margin-right: 75px;
  }
  .add-button {
    width: 30px;
    height: 25px;
    border: 1px solid white;
    border-radius: 10px;
  }
}
.skeleton {
  opacity: 0.7;
  background: linear-gradient(to right, #cfc5c5 0%, #e3d9d9 50%, #f4f1f1 100%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

/* Tabs css */
.tab {
  button {
    height: 40px;
    border-radius: 10px;
    margin-right: 10px;
  }
  margin: 15px;
  margin-left: 0px;
}

.user-input {
  color: aliceblue;
  margin: 10px;
  margin-left: 0px;
  label {
    font-size: 20px;
  }
  input {
    width: 75px;
    height: 30px;
    border-radius: 5px;
  }
}

/* comment */

.comment {
  margin-top: 10px;
  textarea {
    margin-right: 7px;
    border: 1px solid red;
    padding-left: 15px;
    padding-top: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: small;
    border-radius: 5px;
  }
  .post-comment {
    height: 85px;
    color: black;
    font-size: large;
    border: 1px solid red;
    border-radius: 5px;
    background-color: aqua;
  }
}

.fun-buttons {
  margin-top: 10px;
  margin-left: 10px;
  /* button{
        height: 25px;
        margin-left: 5px;
    } */
  button {
    display: block;
    line-height: 40px;
    font-size: 18px;
    font-family: sans-serif;
    text-decoration: none;
    color: #333;
    border: 2px solid #333;
    letter-spacing: 2px;
    text-align: center;
    position: relative;
    transition: all 0.35s;
    border-radius: 5px;
    margin-left: 5px;
  }
  button span {
    position: relative;
    z-index: 2;
  }
  button:hover {
    color: blue;
  }
  button:hover:after {
    width: 100%;
  }
  button:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: yellow;
    transition: all 0.35s;
    border-radius: 5px;
  }
}

.comment-box {
  margin-top: 10px;
  width: 1140px;
  height: 120px;
  border: 1px solid yellow;
  border-radius: 5px;
}

.comment-content {
  margin: 10px;
  color: white;
}

.nested-comments {
  margin-left: 35px;
}

/* //jira-board */

.status-header {
  span {
    min-width: 25%;
    margin-right: 5px;
    border: 1px solid yellow;
    color: yellow;
  }
}

/* Media Queries */

/* General Media Queries */
@media (max-width: 1200px) {
  .container {
    padding-inline: 50px;
  }

  .profile {
    width: 450px;
    height: 400px;
  }

  .contact-me input {
    width: 300px;
  }
}

@media (max-width: 996px) {
  .nav-toggle {
    display: block;
    margin-left: 10px;
  }

  .nav-list,
  .media-links,
  .profile-container {
    display: none;
  }

  .container {
    padding-inline: 20px;
  }

  .profile {
    width: 300px;
    height: 300px;
  }

  .contact-me input {
    width: 250px;
  }

  .comment-box {
    width: 90%;
  }

  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .main-nav {
    flex-direction: column;
    height: auto;
  }

  .container {
    padding-inline: 10px;
  }

  .profile {
    width: 200px;
    height: 200px;
  }

  .contact-me input {
    width: 200px;
  }

  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .main-nav {
    justify-content: center;
    text-align: center;
  }

  .container {
    padding-inline: 5px;
  }

  .profile {
    width: 150px;
    height: 150px;
  }

  .contact-me input {
    width: 100%;
  }

  .comment-box {
    width: 100%;
  }

  .grid-container {
    grid-template-columns: 1fr;
  }

  .fun-buttons button {
    width: 100%;
    margin-bottom: 5px;
  }
}

@media (max-width: 400px) {
  .main-nav {
    height: auto;
  }

  .profile {
    width: 100px;
    height: 100px;
  }

  .contact-me input,
  .contact-me button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .flex {
    flex-direction: column;
  }

  .main-nav {
    align-items: center;
    padding: 10px;
  }
}

@media (max-width: 1200px) {
  .title {
    font-size: 30px;
  }

  .introduction h2 {
    font-size: 35px;
  }

  .introduction h4 {
    font-size: 20px;
  }

  .introduction p {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 25px;
  }

  .introduction h2 {
    font-size: 30px;
  }

  .introduction h4 {
    font-size: 18px;
  }

  .introduction p {
    font-size: 18px;
  }
}

/* Card Media queries */

@media (max-width: 768px) {
  .card {
    width: 100%;
  }

  .shimmer-card {
    width: 100%;
    height: auto;
  }

  .shimmer-card .image-box {
    width: 100%;
  }

  .shimmer-card .title,
  .shimmer-card .tag {
    width: 100%;
    margin: 0;
  }
}

/* Footer Media queries  */

@media (max-width: 768px) {
  footer {
    height: auto;
    padding: 10px;
  }
}

/* Navigation media queries */

@media (max-width: 996px) {
  .nav-list,
  .media-links {
    display: none;
  }
}

@media (min-width: 997px) {
  .nav-toggle {
    display: none;
  }
}

.tab-button {
  width: auto;
  height: 40px;
  border-radius: 25px;
  margin-right: 10px;
  margin-top: 20px;
  font-size: 18px;
}

.selected-button {
  background-color: yellow;
}

/* progress bar  */

.bar-container {
  position: relative;
  margin-top: 50px;
  margin-left: 250px;
  margin-bottom: 10px;
  width: 400px;
  height: 20px;
  border: 3px solid rgb(140, 204, 113);
  border-radius: 10px;
  overflow: hidden;
}

.bar {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: yellow;
}

.question {
  color: yellow;
  font-size: 30px;
}

.done {
  color: yellow;
  font-size: 30px;
  margin-left: 400px;
}

/* faq and accordion */
.faq-container {
  border: 2px solid yellow;
  margin-left: 10px;
  margin-top: 25px;
  h3 {
    margin-left: 10px;
    font-size: 32px;
  }
}

.qna {
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid yellow;
  margin-bottom: 10px;
  h4 {
    position: relative;
    margin-left: 15px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }
  span {
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
  p {
    margin-right: 10px;
    margin-left: 15px;
    color: yellow;
  }
}

/* Star rating functionality */

.rate-container {
  position: relative;
  width: 500px;
  height: 500px;
  margin-top: 20px;
  margin-left: 20px;
  border: 1px solid yellow;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center child elements horizontally */
  .rate-comment {
    width: 500px;
    height: 200px;
    input {
      width: 95%;
      height: 100%;
      margin-left: 10px;
      margin-top: 10px;
      border-radius: 10px;
    }
  }
  button {
    margin-top: 25px;
    width: 200px;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    background-color: yellow;
    border-radius: 10px;
    cursor: pointer;
  }
  button:hover {
    background-color: red;
    color: white;
  }
}

.rate-star {
  margin-top: 15px;
  font-size: 22px;
  font-family: Georgia, "Times New Roman", Times, serif;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center star-rating horizontally */
  span {
    color: yellow;
    margin-bottom: 10px;
  }
  .star-rating {
    width: 350px;
    height: 50px;
    padding-bottom: 10px;
    span {
      margin-left: 30px;
      font-size: 40px;
      cursor: pointer;
    }
  }
}

/* Quiz css */
.quiz-container {
  border: 1px solid yellow;
  margin-top: 10px;
  .quiz-content {
    margin: 20px;
    h3 {
      font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
      font-size: 32px;
    }
    p {
      color: yellow;
      font-size: 24px;
    }
    ol {
      li {
        font-size: 24px;
      }
    }
  }
  .quiz-button {
    button {
      width: 120px;
      height: 40px;
      margin-left: 10px;
      background-color: lightskyblue;
      border-radius: 10px;
      cursor: pointer;
    }
    button:hover {
      background-color: yellow;
    }
  }
}

/* Traffic Light */
.light-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.traffic-light {
  color: yellow;
  .line {
    border: 1px solid yellow;
    margin-top: 10px;
  }
  .light-box {
    width: 105px;
    height: 400px;
    border: 1px solid yellow;
    margin-top: 10px;
    border-radius: 10px;
    padding: 7px;
  }
  .light {
    border: 1px solid yellow;
    width: 100px;
    height: 100px;
    border-radius: 60px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 90%;
  text-align: center;
}

.close-btn {
  display: none;
}

.modal-content {
  padding: 20px;
}

.congratulations-modal .modal-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.modal-icon svg {
  fill: #28a745;
  width: 50px;
  height: 50px;
}

h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 16px;
}

p {
  color: #666;
  font-size: 16px;
  margin-bottom: 24px;
}

.modal-button {
  background-color: #28a745;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

/* stepper */

.stepper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 25px;
}

.stepper {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
}

.stepper-item {
  display: flex;
  align-items: center;
}

.stepper-button {
  padding: 10px 15px;
  border: 2px solid blue;
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}

.stepper-button:hover {
  background: blue;
  color: white;
}

.stepper-connector {
  flex-grow: 1;
  height: 2px;
  background-color: blue;
}

.stepper-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 400px;
  min-height: 200px;
  gap: 20px;
}

.stepper-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.back-button,
.continue-button {
  padding: 10px 20px;
  border: 2px solid blue;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}

.back-button:disabled,
.continue-button:disabled {
  background: #ddd;
  color: #999;
  cursor: not-allowed;
}

/* Toaster */

.toast-container {
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 11;
}

.toast {
  width: 12rem;
  border-radius: 0.5rem;
  color: white;
  padding: 1rem;
  animation: slide 1s;
  position: relative;
  margin: 0.2rem;
  span {
    position: absolute;
    right: 0.7rem;
    cursor: pointer;
  }
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.toast-button {
  height: 100px;
  padding: 100px;
  button {
    width: 200px;
    height: 60px;
    margin: 5px;
    border-radius: 5px;
    background-color: #0e1630;
    border: 1px solid yellow;
    color: #ddd;
  }
  button:hover {
    background-color: white;
    border: 1px solid red;
    color: black;
  }

}
